@tailwind base;
@tailwind components;
@tailwind utilities;


/* body { */
  /* margin: 0; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* font-family: 'Poppins', sans-serif; */
  
/* } */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body {
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
  overflow-x: hidden
}

.selectable:hover {
  cursor: pointer;
  background-color: #D9D9D9;
}

.fill-input {
  background-color: #E3EBF3;
}

.tooltip {
  position: relative;
  display: inline-block;
  }

  .tooltip .tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 50%;
  margin-left: -80px;
  margin-bottom: -40px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: x-small;
  cursor: default;
  }

  .tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  }

  .popup {
      
      left: 50%;
      transform: translate(-50%, 0);
  }

  .selected{
      background-color: #001F3F;
      padding-top: 15px;
      padding-bottom: 15px;
  }

  nav a, nav > div > button:hover {
      transition: all 0.3s ease-in;
  }

  nav a:hover, nav > div > button:hover {
      background-color: #001F3F;
      padding-top: 15px;
      padding-bottom: 15px;
  }



/* Globally customize checkbox color */
input[type="checkbox"] {
  accent-color: #787c84;
}